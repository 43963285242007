<template>
    <GenericList
        ref="lista"
        name="concentrador"
        title="concentradores"
        data-table-state-name="Concentrador"
        add-route="add_concentrador"
        edit-route="edit_concentrador"
        :cols="['#', 'Nome', 'Típico', 'Conversor', 'Código', 'Área', 'Descrição', 'Período de calibração', 'Detectores']"
        :cols-name="['id', 'nome', 'idTipicoConcentrador', 'idConversor', 'codigo', 'idArea', 'descricao', 'dataInicioCalibracao', null]"
        :cols-map="i => [i.id, i.nome, i.tipicoConcentrador.nome, { rota: 'edit_conversor', id: i.idConversor, nome: i.conversor.nome } , i.codigo, { id: i.idArea, nome: i.area.nome }, i.descricao, [ i.dataInicioCalibracao, i.dataFimCalibracao ], i.detectores]"
        :api="api"
    >
        <template #Detectores="{ value }">
            <a href="#" @click.prevent="showModal(value)" title="Exibir lista"><eye-icon size="16" /> {{value.length}} cadastrado{{value.length !== 1 ? 's' : ''}}</a>
        </template>
          <template #[periodo]="{ value }">
            {{formatter(value[0])}} - {{formatter(value[1])}}
        </template>
        <template #Área="{ value }">
            <AreaHierarquica :nome="value.nome" :id="value.id" />
        </template>
    </GenericList>
</template>

<script>
import GenericList from '@/components/GenericList'
import AreaHierarquica from '@/components/AreaHierarquica'
import api from '@/api.js'

export default {
    components: {
        GenericList,
        AreaHierarquica,
    },
    data () {
        return {
            api: api.v1.concentrador.list,
            periodo: 'Período de calibração'
        };
    },
    mounted () {
        this.$refs['lista'].update();
    },
    methods: {
        formatter(data) {
            return data.split('-').reverse().join('/');
        },
        showModal (detectores) {
            this.$swal({
                title: 'Detectores cadastrados',
                html: '<table class="table"><tr><th scope="col">Canal</th><th scope="col">Nome</th></tr>' + (detectores.map(d => `<tr><td>${d.canal}</td><td>${d.nome}</td></tr>`).join('') || '<tr><td colspan="2">(nenhum)</td></tr>') + '</table>',
                confirmButtonText: 'Fechar',
                confirmButtonColor: '#6c757d',
            })
        }
    }
}
</script>

<style scoped>
.feather {
    margin-bottom: 4px;
}
</style>
