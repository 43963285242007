<template>
    <EditBase :loading="loading" :edicao="edicao" :nome="concentrador.nome.toUpperCase()" placeholder="Novo concentrador">
        <form @submit="formSalvar">
            <div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="i_nome">Nome *</label>
                        <input type="text" maxlength="4" required="required" pattern="^[A-Za-z]{2}[0-9]{2}$" :disabled="saving" class="form-control" id="i_nome" placeholder="Formato: 2 letras 2 números. Exemplo: CT01" data-cy="Nome" v-model="concentrador.nome">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="i_descricao">Descrição *</label>
                        <input type="text" maxlength="240" required="required" :disabled="saving" class="form-control" id="i_descricao" placeholder="Exemplo: Sala de descanso" data-cy="Descrição" v-model="concentrador.descricao">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="i_codigo">Código *</label>
                        <input type="text" maxlength="120" required="required" :disabled="saving" class="form-control" id="i_codigo" placeholder="Exemplo: 123456" data-cy="Código" v-model="concentrador.codigo">
                    </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                        <label for="i_tipico">Típico *</label>
                        <multiselect id="i_tipico" :disabled="saving" v-model="concentrador.tipico" :show-labels="false" :allow-empty="false" :searchable="true" :options="listaTipicosConcentradores" label="nome" data-cy="Típico" track-by="id"></multiselect>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="i_conversor">Conversor *</label>
                        <multiselect id="i_conversor" :disabled="saving" v-model="concentrador.conversor" :show-labels="false" :allow-empty="false" :searchable="true" :options="listaConversores" label="nome" data-cy="Conversor" track-by="id"></multiselect>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="i_area">Área *</label>
                        <multiselect id="i_area" :disabled="saving" v-model="concentrador.area" :show-labels="false" :allow-empty="false" :searchable="true" :options="listaAreas" label="nome" data-cy="Área" track-by="id"></multiselect>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col form-group col-md-4">
                        <label for="data-inicial">Início do período de calibração *</label>
                        <datepicker placeholder="Data inicial" id="data-inicial" input-class="bg-white" v-model="concentrador.dataInicioCalibracao" :format="formatter" :language="ptBR" :bootstrap-styling="true" data-cy="Data inicial de calibração"></datepicker>
                    </div>
                    <div class="col form-group col-md-4">
                        <label for="data-final">Fim do período de calibração *</label>
                        <datepicker placeholder="Data final" id="data-final" input-class="bg-white" v-model="concentrador.dataFimCalibracao" :format="formatter" :language="ptBR" :bootstrap-styling="true" data-cy="Data final de calibração"></datepicker>
                    </div>
                    <div class="col form-group col-md-4">
                        <label>Posição do concentrador no mapa</label>
                        <div v-if="hasZoom" class="input-group">
                            <button type="button" class="btn btn-outline-secondary form-control" @click="mapa=true" data-cy="Posicionar no mapa" :disabled="saving">{{ concentrador.marcadorX ? 'Ver/alterar posição no mapa' : 'Posicionar no mapa'}}</button>
                            <div class="input-group-append">
                                <button type="button" :class="concentrador.marcadorX ? 'btn-outline-danger': 'btn-outline-secondary'" class="btn form-control" @click="apagarMarcador" data-cy="Apagar do mapa" :disabled="saving || concentrador.marcadorX === null"><Trash2Icon /></button>
                            </div>
                        </div>
                        <p v-else class="mt-1"><InfoIcon /> A área selecionada não possui mapa vinculado</p>
                    </div>
                </div>
                <p class="small text-right">Campos marcados com * são obrigatórios.</p>
            </div>
            <hr>
            <div class="d-flex justify-content-between pb-4">
                <div class="align-self-center">
                    <h2 v-if="!edicao">Cadastro de detectores</h2>
                    <h2 v-else>Edição de detectores</h2>
                </div>
                <div class="align-self-center">
                    <span class="mr-4" v-if="qtdMaxDetectores >= detectores.length">
                        {{ qtdMaxDetectores ? detectores.length : '-' }}/{{ qtdMaxDetectores ? qtdMaxDetectores : '-'}}
                    </span>
                    <span class="mr-4" v-else>
                        <span style="color: red; font-weight: bold;">{{ qtdMaxDetectores ? detectores.length : '-' }}</span>/{{ qtdMaxDetectores ? qtdMaxDetectores : '-'}}
                    </span>
                    <button type="button" class="btn btn-info" data-cy="Adicionar" @click="addDetector" :disabled="saving || !canAddDetector">Adicionar</button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table" data-cy="TabelaDetectores">
                    <thead>
                        <tr>
                            <th>Canal *</th>
                            <th>SAP *</th>
                            <th>Nome *</th>
                            <th>Descrição *</th>
                            <th>Localização</th>
                            <th v-if="showPimsOptions">Range *</th>
                            <th v-if="showPimsOptions">Unidade *</th>
                            <th v-if="showPimsOptions">Tipo de Gás *</th>
                            <th v-if="showPimsOptions">Tag de Valor *</th>
                            <th v-if="showPimsOptions">Tag de Alerta *</th>
                            <th v-if="showPimsOptions">Tag de Alarme *</th>
                            <th v-if="showPimsOptions" title="Coloque os nomes dos elementos separados por barras">Elementos * <AlertCircleIcon size="16" color="#0F6C4F" /></th>
                            <th>Início do período de calibração *</th>
                            <th>Fim do período de calibração *</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-show="!detectores || detectores.length === 0">
                        <tr><td colspan="7" class="text-center" :style="showPimsOptions ? 'min-width: 1100px' : ''">Nenhum detector cadastrado.</td></tr>
                    </tbody>
                    <tbody v-show="detectores && detectores.length > 0">
                        <tr v-for="(td, idx) in detectores" :key="td.id" :class="{ 'bg-danger-light': idx >= qtdMaxDetectores }" class="destaque" :id="`form-detector-${td.id}`">
                            <td>
                                <input type="number" min="1" :max="qtdMaxDetectores" required="required" :disabled="saving" class="form-control" placeholder="Ex: 1" v-model="td.canal" :data-cy="`DetectorCanal${idx}`">
                            </td>
                            <td>
                                <input type="text" maxlength="140" required="required" :disabled="saving" class="form-control" placeholder="Ex: 123" v-model="td.sap" :data-cy="`DetectorSAP${idx}`">
                            </td>
                            <td>
                                <input type="text" maxlength="120" required="required" :disabled="saving" class="form-control" placeholder="Ex: GDT-201" v-model="td.nome" :data-cy="`DetectorNome${idx}`">
                            </td>
                            <td>
                                <input type="text" maxlength="240" required="required" :disabled="saving" class="form-control" placeholder="Ex: Detectores de CO" v-model="td.descricao" :data-cy="`DetectorDescrição${idx}`">
                            </td>
                            <td>
                                <input type="text" maxlength="240" :disabled="saving" class="form-control" placeholder="Ex: Vestiário Aciaria" v-model="td.localizacao" :data-cy="`DetectorLocalizacao${idx}`">
                            </td>

                            <td v-if="showPimsOptions">
                                <input type="number" required="required" :disabled="saving" class="form-control" placeholder="Ex: 300" v-model="td.range" :data-cy="`DetectorRange${idx}`">
                            </td>
                            <td v-if="showPimsOptions">
                                <input type="text" maxlength="240" required="required" :disabled="saving" class="form-control" placeholder="Ex: PPM" v-model="td.unidade" :data-cy="`DetectorUnidade${idx}`">
                            </td>
                            <td v-if="showPimsOptions">
                                <input type="text" maxlength="240" required="required" :disabled="saving" class="form-control" placeholder="Ex: CO" v-model="td.tipoGas" :data-cy="`DetectorTipoGas${idx}`">
                            </td>
                            <td v-if="showPimsOptions">
                                <input type="text" maxlength="240" required="required" :disabled="saving" class="form-control" placeholder="Ex: U1_Q_AF3" v-model="td.tagValor" :data-cy="`DetectorTagValor${idx}`">
                            </td>
                            <td v-if="showPimsOptions">
                                <input type="text" maxlength="240" required="required" :disabled="saving" class="form-control" placeholder="Ex: U1_ALERTA" v-model="td.tagAlerta" :data-cy="`DetectorTagAlertao${idx}`">
                            </td>
                            <td v-if="showPimsOptions">
                                <input type="text" maxlength="240" required="required" :disabled="saving" class="form-control" placeholder="Ex: U1_ALARME" v-model="td.tagAlarme" :data-cy="`DetectorTagAlarme${idx}`">
                            </td>
                            <td v-if="showPimsOptions">
                                <input type="text" maxlength="240" required="required" :disabled="saving" class="form-control" placeholder="Ex: UNIDADES/U1" v-model="td.elemento" :data-cy="`DetectorElemento${idx}`">
                            </td>

                            <td>
                                <datepicker placeholder="Data inicial" id="data-inicial" input-class="bg-white" v-model="td.dataInicioCalibracao" :format="formatter" :language="ptBR" :bootstrap-styling="true" :data-cy="`DataInicioCalibracao${idx}`"></datepicker>
                            </td>
                            <td>
                                <datepicker placeholder="Data final" id="data-final" input-class="bg-white" v-model="td.dataFimCalibracao" :format="formatter" :language="ptBR" :bootstrap-styling="true" :data-cy="`DataFimCalibracao${idx}`"></datepicker>
                            </td>
                            <td class="w-custom">
                                <button type="button" class="form-control btn btn-warning" :data-cy="`RemoverDetector${idx}`" @click="removeDetector(td)">Remover</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p class="small text-right">Campos marcados com * são obrigatórios.</p>
            <div v-if="edicao" class="d-flex justify-content-between">
                <button class="btn btn-danger align-self-center lg" type="button" data-cy="Remover" @click="remover" :disabled="loading || saving || !edicao">Remover</button>
                <button class="btn btn-success align-self-center lg" type="submit" data-cy="Salvar" :disabled="loading || saving || !isComplete">Salvar</button>
            </div>
             <div v-else class="d-flex justify-content-end">
                <button class="btn btn-success align-self-center lg" type="submit" data-cy="Salvar" :disabled="loading || saving || !isComplete">Salvar</button>
            </div>
        </form>
        <transition name="fade">
            <floating-panel ref="floating-panel" title="Posicione o concentrador" :left="20" :top="20" :width="800" :height="500" v-show="mapa" @close="mapa=false" @resize="resize">
                <editor-mapa :key="editorKey" ref="editor-mapa" :nome="concentrador.nome" :mapaUrl="mapaUrl" :x.sync="concentrador.marcadorX" :y.sync="concentrador.marcadorY"/>
            </floating-panel>
        </transition>
    </EditBase>
</template>

<script>
function options (timeout) {
    return {
        timeout: timeout || 2000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
    }
}

import { copyProps, TRY_TIMEOUT } from '@/helpers/common.js'
import axios from 'axios'
import api from '@/api.js'

import { ptBR } from 'vuejs-datepicker/dist/locale'
import Datepicker from 'vuejs-datepicker';

import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

import EditBase from '@/templates/EditBase'
import FloatingPanel from "@/components/Sinotico/FloatingPanel";
import EditorMapa from "./EditorMapa";

export default {
    name: 'AddEditConcentrador',
    components: {
        EditBase,
        Datepicker,
        EditorMapa,
        FloatingPanel
    },
    data () {
        return {
            ptBR,
            mapa: false,
            concentrador: {
                id: null,
                nome: '',
                descricao: '',
                codigo: '',
                idTipicoConcentrador: null,
                idConversor: null,
                idArea: null,
                tipico: null,
                conversor: null,
                area: null,
                dataInicioCalibracao: null,
                dataFimCalibracao: null,
                marcadorX: null,
                marcadorY: null,
            },
            listaTipicosConcentradores: [],
            listaConversores: [],
            listaAreas: [],
            detectores: [],
            idDetectoresRemovidos: [],
            errMsg: '',
            loading: true,
            edicao: false,
            saving: false,
            mapaUrl: '/zooms/blank.svg',
            hasZoom: false,
            editorKey: 0,
        }
    },
    computed: {
        isComplete () {
            let t=this.concentrador;
            let detectorValidDate = true;
            if(this.detectores.length > 0) {
                detectorValidDate = !this.detectores.some(d => d.dataInicioCalibracao === null || d.dataFimCalibracao === null);
            }
            return !!(detectorValidDate && t.dataInicioCalibracao &&t.dataFimCalibracao && t.nome && t.descricao && t.codigo && t.tipico && (this.edicao || t.conversor) && t.area && this.qtdMaxDetectores >= this.detectores.length);
        },
        canAddDetector () {
            return  this.qtdMaxDetectores > this.detectores.length;
        },
        qtdMaxDetectores() {
            return this.concentrador.tipico ? this.concentrador.tipico.max : 0;
        },
        showPimsOptions() {
            if (this.concentrador.tipico) {
                return  this.concentrador.tipico.nome === 'PIMS' ? 1 : 0;
            } else {
                return 0;
            }
        }
    },
    methods: {
        apagarMarcador () {
            this.concentrador.marcadorX = this.concentrador.marcadorY = null;
        },
        resize () {
            this.$refs['editor-mapa'].resize();
        },
        mudaMapa () {
            if (this.concentrador.area.nZoom) {
                this.mapaUrl = `/zooms/${this.concentrador.area.nZoom}.svg`;
                this.hasZoom = true;
            } else if (this.concentrador.area.idAreaPai) {
                axios.get(api.v1.area.listRecursiveByIds([this.concentrador.area.id])).then( res => {
                    let areasReq = [];
                    let id = this.concentrador.area.id;
                    while (id) {
                        let area = res.data.find(a => a.id === id);
                        area.parent = res.data.find(a => a.id === area.idAreaPai) || null;
                        areasReq.push(area);
                        id = area.parent ? area.parent.id : null;
                    }
                    let a = areasReq.length > 0 ? areasReq[0] : null;
                    let nZoom = a ? a.nZoom : null;
                    while (a && !nZoom) {
                        a =  a.parent;
                        nZoom = a ? a.nZoom : null;
                    }
                    if (!nZoom) this.hasZoom = false;
                    else {
                        this.mapaUrl = `/zooms/${nZoom}.svg`;
                        this.hasZoom = true;
                    }
                });
            } else if (this.$refs['floating-panel']) {
                this.$refs['floating-panel'].close();
                this.hasZoom = false;
            } else this.hasZoom = false;
            this.editorKey++;
        },
        formatter (date) {
            return dayjs(date).locale('pt-br').format('D [de] MMMM [de] YYYY');
        },
        addDetector () {
            const d = this.detectores[this.detectores.length-1];
            this.detectores.push({ id: null, nome: '', descricao: '', localizacao: '', idConcentrador: null, canal: d ? +d.canal+1 : 1,  dataInicioCalibracao: null, dataFimCalibracao: null})
        },
        updateListaTipicos () {
            return axios.get(api.v1.tipicoConcentrador.list(1, -1)).then(res => {
                this.listaTipicosConcentradores = res.data.rows.map(el => ({
                    id: el.id,
                    nome: el.nome,
                    max: el.qtdMaxDetectores,
                }));
            }).catch(reason => {
                this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
            });
        },
        updateListaConversores () {
            /*  É preciso listar apenas os conversores disponíveis, ou seja, conversores que não estão associados a nenhum detector */
            axios.get(api.v1.concentrador.list(1, -1)).then(r => {
                const idConversoresProibidos = new Set(r.data.rows.map(el => {
                    /* Se for edição */
                    if(this.concentrador.id != el.id)
                        return el.idConversor
                    }));
                return axios.get(api.v1.conversor.list(1, -1)).then(res => {
                    let idConversoresPermitidos = new Set(res.data.rows.map(el => el.id));

                    /*Diferença entre os conjuntos */
                    for (let elem of idConversoresProibidos) {
                        idConversoresPermitidos.delete(elem);
                    }
                    idConversoresPermitidos = [...idConversoresPermitidos];

                    this.listaConversores = res.data.rows.filter(e => idConversoresPermitidos.findIndex(i => i === e.id) >= 0).map(el => ({
                        id: el.id,
                        nome: el.nome,
                    }));
                    /* Se for edição */
                    if(this.concentrador.id) {
                        this.concentrador.conversor = this.listaConversores.find(el => el.id === this.concentrador.idConversor);
                    }
                }).catch(reason => {
                    this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
                });
            });

        },
        updateListaAreas () {
            return axios.get(api.v1.area.list(1, -1)).then(res => {
                this.listaAreas = res.data.rows.map(el => ({
                    id: el.id,
                    nome: el.nome,
                    nZoom: el.nZoom,
                    idAreaPai: el.idAreaPai
                }));
            }).catch(reason => {
                this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
            });
        },
        getConcentrador () {
            let id = this.$route.params.id;
            this.edicao = !!id;
            this.loading = this.edicao; // se for adição loading=false;
            if (this.edicao) {
                axios.get(api.v1.concentrador.get(id))
                .then(res => {
                    if (res.data == null) { // concentrador não existe
                        this.$snotify.error('O concentrador solicitado não existe.', 'Erro');
                        this.$router.replace('/concentradores');
                    }
                    let concentrador = copyProps(res.data)(['id', 'nome', 'descricao', 'codigo', 'idTipicoConcentrador', 'idConversor', 'idArea', 'marcadorX', 'marcadorY', 'dataInicioCalibracao', 'dataFimCalibracao']);
                    concentrador.tipico = this.listaTipicosConcentradores.find(el => el.id === concentrador.idTipicoConcentrador);
                    concentrador.area = this.listaAreas.find(el => el.id === concentrador.idArea);

                    const agora = new Date();

                    this.concentrador = {
                        ...concentrador,
                        dataInicioCalibracao: new Date((new Date(concentrador.dataInicioCalibracao)).getTime() + agora.getTimezoneOffset()*60*1000),
                        dataFimCalibracao: new Date((new Date(concentrador.dataFimCalibracao)).getTime() + agora.getTimezoneOffset()*60*1000),
                    };

                    this.mudaMapa();

                    this.detectores = res.data.detectores.map(detector => {
                        return {
                            ...detector,
                            dataInicioCalibracao: new Date((new Date(detector.dataInicioCalibracao)).getTime() + agora.getTimezoneOffset()*60*1000),
                            dataFimCalibracao: new Date((new Date(detector.dataFimCalibracao)).getTime() + agora.getTimezoneOffset()*60*1000),
                        }
                    });

                    this.loading = false;
                })
                .catch(reason => {
                    console.trace(reason);
                    this.$snotify.warning('Não foi possível buscar o concentrador, tentando novamente...', 'Erro');
                    setTimeout(() => this.getConcentrador(), TRY_TIMEOUT);
                })
            }
        },
        limparCampos() {
            this.concentrador = {
                id: null,
                nome: '',
                descricao: '',
                codigo: '',
                idTipicoConcentrador: null,
                idConversor: null,
                idArea: null,
                tipico: null,
                concentrador: null,
                area: null,
                dataInicioCalibracao: null,
                dataFimCalibracao: null
            };
        },
        salvar () {
            this.saving = true;
            this.$snotify.async('Aguarde...', 'Salvando', () => new Promise((resolve, reject) => {
                this.concentrador.idTipicoConcentrador = this.concentrador.tipico && this.concentrador.tipico.id;
                this.concentrador.idConversor = this.concentrador.conversor && this.concentrador.conversor.id;
                this.concentrador.idArea = this.concentrador.area && this.concentrador.area.id;
                let payload = copyProps(this.concentrador)(['id','nome', 'descricao','codigo', 'idTipicoConcentrador','idConversor', 'idArea', 'dataInicioCalibracao', 'dataFimCalibracao', 'marcadorX', 'marcadorY']);
                payload.detectores = this.detectores;
                payload.idDetectoresRemovidos = this.idDetectoresRemovidos;
                let req = this.edicao ? axios.put(api.v1.concentrador.update, payload)
                                      : axios.post(api.v1.concentrador.create, payload);
                req.then(res => {
                    this.concentrador = res.data;
                    this.saving = false;
                    if (!this.edicao) {
                        this.detectores = [];
                        this.idDetectoresRemovidos = [];
                        this.limparCampos();
                        this.updateListaConversores();
                    } else {
                        this.$router.push({ name: 'concentradores' });
                    }
                    resolve({
                        title: 'Sucesso!',
                        body: 'Concentrador salvo',
                        config: options()
                    })
                }).catch(e => {
                    this.saving = false;
                    let error = e.response.data ? e.response.data.error : e.toString();
                    if(e.response.status === 409 && error === 'Validation error' ) {
                        error = 'Impossível associar mais de um detector ao mesmo canal.'
                    }
                    reject({
                        title: 'Erro ao salvar',
                        body: error,
                        config: options(10000)
                    })
                });
            }));
        },
        formSalvar (e) {
            e.preventDefault();
            this.salvar();
            return false;
        },
        removeDetector(td) {
            if(td.id) this.idDetectoresRemovidos.push(td.id);
            let removeIndex = this.detectores.findIndex(el => el === td);
            this.detectores.splice(removeIndex, 1);
        },
        remover () {
            const doDelete = () => {
                return axios.delete(api.v1.concentrador.destroy, { data: { id: this.concentrador.id } })
                .then(res => {
                    return res.data.error ? Promise.reject(res.data.error) : res.data;
                })
                .catch(reason => {
                    if(reason.response && reason.response.data && reason.response.data.error) {
                        this.$swal.showValidationMessage('Falha ao excluir: ' + (reason.response.data.error));
                    }
                    else this.$swal.showValidationMessage('Falha ao excluir: ' + (reason ? reason.toString() : 'motivo desconhecido.'));
                })
            };

            // TODO: transformar em função auxiliar para evitar duplicação de código
            this.$swal.fire({
                title: 'Você tem certeza?',
                text: "A remoção de um concentrador é irreversível",
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                focusCancel: true,
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Remover',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: () => !this.$swal.isLoading(),
                showLoaderOnConfirm: true,
                preConfirm: doDelete
            }).then(res => {
                if (res.value) {
                    this.$router.replace('/concentradores');
                    this.$snotify.success('Concentrador removido', 'Sucesso!');
                }
            })
        },
    },
    watch: {
        '$route': 'getConcentrador',
        'concentrador.area': 'mudaMapa'
    },
    created () {
        Promise.all([
            this.updateListaTipicos(),
            this.updateListaAreas(),
        ]).then(() => {
            return Promise.all([this.getConcentrador()]);

        }).then(() => {
                this.updateListaConversores();
        });
    },
}
</script>
<style scoped>
    .w-custom {
        width: 100px;
    }

    table th {
        min-width: 140px;
        max-width: 180px;
    }

    @media screen and (max-width: 576px) {
        .form-row {
            display: block;
        }
    }
</style>
