<template>
    <object ref="mapa" id="mapa-zoom-area" :data="mapaUrl" type="image/svg+xml" style="width:100%; height:calc(100% - 0.75rem)" @load="onLoad"></object>
</template>
<script>
let mapInstance;
const resizeMapFn = () => {
    if (!mapInstance) return;
    mapInstance.resize();
    mapInstance.fit();
    mapInstance.center();
};
import svgPanZoom from 'svg-pan-zoom';
import { matrixInvert, matrixDot } from '@/helpers/matrix';
import { hSvg } from '@/helpers/h';

const textStyle = {
    fill: '#28166f',
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: 'Arial sans-serif',
}

function createCircle(x, y, up, down) {
    const r = 18.5;
    return hSvg('g', null, [
        hSvg('circle', {
            r,
            cx: x,
            cy: y,
            style: {
                fill: '#ffffff',
                stroke: '#28166f',
                strokeWidth: 2,
            },
        }),
        hSvg('line', {
            x1: x - r,
            y1: y,
            x2: x + r,
            y2: y,
            style: {
                stroke: '#28166f',
                strokeWidth: 2,
            }
        }),
        hSvg('text', {
            x,
            y: y - 3,
            'text-anchor': 'middle',
            style: textStyle,
        }, [up]),
        hSvg('text', {
            x,
            y: y + 13,
            'text-anchor': 'middle',
            style: textStyle,
        }, [down]),
    ]);
}

export default {
    props: {
        nome: {
            type: String,
            required: true
        },
        mapaUrl: {
            type: String,
            required: true,
        },
        x: {
            type: Number,
            required: false,
            default: null,
        },
        y: {
            type: Number,
            required: false,
            default: null,
        }
    },
    data () {
        return {
            showMapa: false,
            circle: null,
        }
    },
    methods: {
        resize () {
            setTimeout(resizeMapFn);
            setTimeout(resizeMapFn, 1000);
        },
        onLoad () {
            mapInstance = svgPanZoom('#mapa-zoom-area', {
                zoomEnabled: false,
                controlIconsEnabled: false,
                mouseWheelZoomEnabled: false,
                preventMouseEventsDefault: false,
                panEnabled: false,
                dblClickZoomEnabled: false,
            });
            this.updateCirculo();
            this.posicionaNoMapa();
        },
        posicionaNoMapa () {
            let m = this.$refs.mapa.contentDocument;
            const el = m.getElementsByClassName("svg-pan-zoom_viewport")[0];
            el.style.userSelect = 'none';
            m.addEventListener('click', ev => {
                if (this.circle) el.removeChild(this.circle);

                let { x, y } = ev;
                let transform = el.style.transform;
                if (!transform) return;
                let match = /matrix\((.+?)\)/.exec(transform) || [];
                if (!match) return;
                const nums = match[1].split(',').map(n => +(n.trim()));
                const matrix = [
                    [nums[0], nums[2], nums[4]],
                    [nums[1], nums[3], nums[5]],
                    [0, 0, 1],
                ];
                const point = [[x], [y], [1]];
                const inverse = matrixInvert(matrix);
                const [[nx], [ny]] = matrixDot(inverse, point);

                this.circle = createCircle(nx, ny, this.nome.substr(0, 2).toUpperCase(), this.nome.substr(2).substr(this.nome.length-4).toUpperCase());
                el.appendChild(this.circle);
                this.$emit('update:x', nx);
                this.$emit('update:y', ny);
            });
        },
        updateCirculo () {
            let m = this.$refs.mapa.contentDocument;
            if (!m) return;
            const el = m.getElementsByClassName("svg-pan-zoom_viewport")[0];
            if (this.circle) {
                el.removeChild(this.circle);
                this.circle = null;
            }
            if(this.x !== null && this.y !== null) {
                this.circle = createCircle(this.x, this.y, this.nome.substr(0, 2).toUpperCase(), this.nome.substr(2).substr(this.nome.length-4).toUpperCase());
                el.appendChild(this.circle);
            }
        },
    },
    watch: {
        'nome': 'updateCirculo',
        'x': 'updateCirculo',
        'y': 'updateCirculo',
        mapaUrl() {
            this.circle = null;
        }
    },
}
</script>
